import api from 'api'
import React, { useEffect, useState } from 'react'
import InvestingChart from './InvestingChart'
const InvestingChartContainer = () => {
  // const investingChart = api.useGetInvestingChartQuery()
  const [data, setData] = useState()

  // useEffect(() => {
  //   if (investingChart?.data?.times) {
  //     const chartData = [] as [number, number][]
  //     const ranges = [] as [number, number, number][]
  //     const up_bound = investingChart.data.up_bound!
  //     const down_bound = investingChart.data.down_bound!
  //     const chart = investingChart.data.chart!
  //     investingChart.data.times.forEach((ts: any, i: any) => {
  //       chartData.push([ts * 1000, chart[i]])
  //       ranges.push([ts * 1000, down_bound[i], up_bound[i]])
  //     })
  //     const detailedGoalData = {
  //       chart: [chartData, ranges],
  //       payments: [],
  //     }
  //     //@ts-ignore
  //     setData(detailedGoalData)
  //   }
  // }, [investingChart])

  return (
    <InvestingChart
      //@ts-ignore
      loading={false}
      //@ts-ignore
      payments={data?.payments}
      //@ts-ignore
      // charts={data?.chart}
      charts={[
        [
          [1655193571593.314, 14004.709398185041],
          [1656575971593.314, 15081.285075815855],
          [1658390371593.314, 15188.17987592597],
        ],
        [[1655193635583.8052, 14003.484736146129, 14005.321729204497]],
      ]}
      simulate={function (): void {
        throw new Error('Function not implemented.')
      }}
    />
  )
}

export default InvestingChartContainer
