import React, { useEffect } from 'react'
import Router from './Router'
import api from './api'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import useAFK from 'hooks/useAFK'

export const LOGO = process.env.REACT_APP_ASSETS_URL + '/logo.svg'
const App = () => {
  useAFK()
  const user = api.useGetUserInfoQuery()
  const [getClient, resultClient] = api.useLazyGetImportedClientQuery()
  useEffect(() => {
    if (user.isSuccess) getClient({ user: user.data.id, seller_firm: Number(process.env.REACT_APP_FIRM) })
  }, [user])
  return (
    <>
      <Router />
      <ToastContainer />
    </>
  )
}

export default App
