import React from 'react'
import styles from './CreatePortfolio.module.scss'
import api from 'api'
import Button from 'components/Button/Button'
import { useSelector } from 'store/store'

const CreatePortfolio = () => {
  const [createPortfolio, result] = api.usePostDemandMutation()
  const client_id = useSelector((store) => store.profile.client_id)
  return (
    <div className={styles.page}>
      <h1 style={{ marginBottom: 32 }}>Create Portfolio</h1>
      {/* @ts-ignore */}
      <Button onClick={() => createPortfolio({ type: 'create', client: client_id })}>Apply for a portfolio</Button>
    </div>
  )
}

export default CreatePortfolio
