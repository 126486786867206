import React from 'react'

type PropsType = {
  status: string
}
const PortfolioStatus: React.FC<PropsType> = ({ status }) => {
  return <h1>Status: {status}</h1>
}

export default PortfolioStatus
