import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type InitialStateType = Partial<User> & {
  isAuth: boolean
  isAFK: boolean
  client_id: undefined | number
}

const initialState: InitialStateType = {
  isAuth: false,
  isAFK: false,
  client_id: undefined,
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        // @ts-ignore
        state[key] = value
      })
      state.isAuth = true
    },
    updateUserInfo: (state, action: PayloadAction<Partial<User> | { client_id: number }>) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        // @ts-ignore
        state[key] = value
      })
    },
    logout: () => initialState,
    setAFK: (state, action: PayloadAction<boolean>) => {
      state.isAFK = action.payload
    },
  },
})

export const profileActions = profileSlice.actions
export const profileReducer = profileSlice.reducer
