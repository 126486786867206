import React, { useEffect, useRef } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import styles from './InvestingChart.module.scss'
import classNames from 'classnames'
import { number2currency } from '../../../utils'
import moment from 'moment'
import { HighChartsRef } from 'utils/hc-utils'
import { useSelector } from 'store/store'

require('highcharts/modules/boost')(Highcharts)
require('highcharts/highcharts-more')(Highcharts) // for arearange

type Props = {
  simulate: () => void
  payments: any
  charts: [[number, number][], [number, number, number][]]
  data?: { name: string; data: [number, number][] }[]
  name?: string
  mapNavigation?: Highcharts.MapNavigationOptions
  height?: number
  yTitle?: string
  loading?: boolean
  className?: string
}
const InvestingChart: React.FC<Props> = ({ data, name, ...props }) => {
  const themeColor = '#1890ff'

  const ref = useRef<HighChartsRef>()
  useEffect(() => {
    if (ref.current) {
      if (props.loading === true) {
        ref.current.chart.showLoading()
      }
      if (props.loading === false) {
        ref.current.chart.hideLoading()
      }
    }
  }, [props.loading, ref.current])
  const options: Highcharts.Options = {
    time: {
      useUTC: false,
    },
    mapNavigation: {
      ...props.mapNavigation,
    },
    chart: {
      animation: {
        duration: 0,
      },
      zoomType: 'x',
      type: 'line',
      reflow: true,
      height: props.height,
      resetZoomButton: {
        position: {
          align: 'left',
          verticalAlign: 'top',
          y: -4,
          x: -2,
        },
        // relativeTo: 'spacingBox',
      },
    },
    boost: {
      useGPUTranslations: true,
      enabled: true,
      allowForce: true,
      seriesThreshold: 1,
    },
    title: { text: '' },
    scrollbar: { enabled: false },
    rangeSelector: { enabled: false },
    navigator: { enabled: false },
    xAxis: {
      type: 'datetime',
      ordinal: false,

      // ...props.xAxis,
    },
    yAxis: {
      labels: {
        align: 'left',
      },
      title: {
        //@ts-ignore
        enabled: false,
        text: props.yTitle ?? 'y axis',
      },
    },
    tooltip: {
      valueDecimals: 1,
      split: false,
      shared: true,
      formatter: function (a) {
        // @ts-ignore
        return this.points.reduce(function (s, point) {
          // @ts-ignore
          if (point.series.initialType === 'arearange') {
            // @ts-ignore
            const l = number2currency(point.point.low)
            // @ts-ignore
            const h = number2currency(point.point.high)
            return s + '<br/><br/>Min: ' + l + '<br/>Max: ' + h
          } else return s + '<br/><br/>Balance: ' + number2currency(point.y ?? 0)
        }, '<b>' + moment(this.x).format('MM/DD/YYYY') + '</b>')
      },
    },
    plotOptions: {
      line: {
        tooltip: {
          xDateFormat: '%y',
        },
      },
      spline: {
        states: {
          hover: {
            animation: {
              duration: 0,
            },
            enabled: true,
            lineWidth: 1,
          },
        },
      },
    },
    series: [
      {
        zoneAxis: 'x',
        id: 'lineser',
        type: 'line',
        name: 'Chart',
        data: props.charts[0],
        zones: [
          {
            // value: 1801834114242.992,
            value: new Date().getTime() + 1000 * 60 * 60 * 24 * 365,
            color: themeColor,
          },
          {
            color: '#f7a35c',
          },
        ],
        lineWidth: 3,
        states: { hover: { lineWidth: 1, opacity: 1 } },
        color: themeColor,
      },
      // {
      //   id: 'std',
      //   type: 'arearange',
      //   name: 'std',
      //   data: props.charts[1],
      //   zones: [
      //     {
      //       // value: 1801834114242.992,
      //       value: new Date().getTime() + 1000 * 60 * 60 * 24 * 365,
      //       color: themeColor,
      //     },
      //     {
      //       color: '#f7a35c',
      //     },
      //   ],
      //   lineWidth: 0,
      //   color: themeColor,
      //   linecap: 'butt',
      //   linkedTo: 'lineser',
      //   fillOpacity: 0.3,
      //   marker: {
      //     enabled: false,
      //   },
      // },
    ],
  }

  return (
    <div className={classNames(styles.container, props.className)}>
      <HighchartsReact
        //@ts-ignore
        ref={ref}
        highcharts={Highcharts}
        options={options}
        constructorType={'stockChart'}
        style={{ width: '100%' }}
      />
      {/* <Button type={'primary'} className={styles.simulateBtn} onClick={props.simulate} loading={props.loading}>
        Simulate
      </Button> */}
    </div>
  )
}

export default InvestingChart
