import React from 'react'
import styles from './ProductCard.module.scss'
import { number2currency } from 'utils'
import VideoPlayer from 'components/VideoPlayer/VideoPlayer'
import { useNavigate } from 'react-router'
import Gallery from '../Gallery/Gallery'
import LazyLoading from '../LazyLoading/LazyLoading'
import SVG from 'components/SVG'
import api from 'api'
import classNames from 'classnames'

type PropsType = {
  data: Product
  liked?: boolean
}

const ProductCard: React.FC<PropsType> = ({ data, liked }) => {
  const navigate = useNavigate()
  const [like] = api.usePostLikeMutation()
  const [deleteLike] = api.useDeleteLikeMutation()

  const galleryItems: React.ReactNode[] = data.images.map((i) => (
    <LazyLoading key={i.id} src={i.url} width={500} style={{ background: '#' + i.background_color }} />
  ))

  const onClickLike = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (liked) deleteLike(data.issuer_firm_product_id ?? data.id)
    else like(data.issuer_firm_product_id ?? data.id)
  }

  return (
    <div
      className={styles.container}
      onClick={() => {
        navigate(`/product/${data.issuer_firm_product_id ?? data.id}`)
      }}>
      <Gallery bullets controls className={styles.gallery}>
        {galleryItems}
      </Gallery>
      <div className={styles.info}>
        <p className={styles.name}>{data.name}</p>
        <div className={classNames([styles.like, { [styles.liked]: liked }])} onClick={onClickLike}>
          <SVG.Like />
        </div>
        <p className={styles.price}>{data.price > 0 && `${data.price_name}: ${number2currency(data.price)}`}</p>
      </div>
    </div>
  )
}

export default ProductCard
