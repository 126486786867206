import React from 'react'
import styles from './Portfolio.module.scss'
import FundsBlock from './Components/FundsBlock'
import GoalsBlock from './Components/GoalsBlock'
import MainBlock from './Components/MainBlock'

type PropsType = {
  portfolio: Portfolio
}
const Portfolio: React.FC<PropsType> = ({ portfolio }) => {
  const initialAmmount = 3015450
  const balance = 4220465

  return (
    <div className={styles.page}>
      <MainBlock balance={balance} initialAmmount={initialAmmount} />
      <GoalsBlock balance={balance} />
      <FundsBlock assets={portfolio.assets} equity={portfolio.equity} />
    </div>
  )
}

export default Portfolio
