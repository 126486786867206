import api from 'api'
import React from 'react'
import CreatePortfolio from './CreatePortfolio'
import Portfolio from './Portfolio'
import Loading from '../Loading/Loading'
import { useSelector } from 'store/store'
import PortfolioStatus from './Components/PortfolioStatus'

const InvestingContainer = () => {
  const isHasPortfolio = true
  const client_id = useSelector((store) => store.profile.client_id)
  const demand = api.useGetDemandQuery({ client: client_id!, create: 1 })
  console.log('demand', demand)

  const portfolio = api.useGetPortfolioQuery({ client: client_id! })
  if (!portfolio?.data) return <Loading />
  if ('status' in portfolio?.data) {
    if (demand?.data?.status) return <PortfolioStatus status={demand?.data?.status.name_for_user} />
    else return <CreatePortfolio />
  } else if (isHasPortfolio) return <Portfolio portfolio={portfolio.data} />
  else return <CreatePortfolio />
  // if (!demand.data) return <Loading />
  // if (demand.data.length === 0) return <CreatePortfolio />
  // else return <Portfolio />
}

export default InvestingContainer
