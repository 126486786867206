import React, { useState } from 'react'
import styles from './PortfolioConstructor.module.scss'
import Button from 'components/Button/Button'
import Slider from 'components/Slider/Slider'
import ThreeSelect from 'components/ThreeSelect/ThreeSelect'
import api from 'api'
import Loading from 'pages/Loading/Loading'

const PortfolioConstructor = () => {
  const [selectedProducts, setSelectedProducts] = useState<number[]>([])
  const [productsError, setProductsError] = useState<string | undefined>()
  const [riskLevel, setRiskLevel] = useState<number>(5)
  const [dailyDrawdown, setDailyDrawdown] = useState<number>(1)
  const [sharpe, setSharpe] = useState<number>(0.5)
  const { data: categories, isLoading: categoriesLoading } = api.useGetInvestingCategoriesQuery({
    seller_firm: Number(process.env.REACT_APP_FIRM),
  })

  const validate = () => {
    let hasError = false

    if (selectedProducts.length < 1) {
      setProductsError('Please specify the products')
      hasError = true
    }

    return !hasError
  }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const isValid = validate()
  }

  if (categoriesLoading) return <Loading />
  if (categories === undefined) return <p>No products</p>
  return (
    <div className={styles.page}>
      <h1 style={{ marginBottom: 32 }}>Change Portfolio</h1>
      <form className={styles.form} onSubmit={onSubmit}>
        <div className={styles.formField}>
          <label>Categories:</label>
          <ThreeSelect
            onChange={(v) => {
              setSelectedProducts(v.map((p) => p.id))
              setProductsError(undefined)
            }}
            options={categories.map((c) => ({
              id: c.id,
              label: c.name,
              children: c.products.map((p) => ({ id: p.id, label: p.name })),
            }))}
            error={productsError}
          />
        </div>
        <div className={styles.formField}>
          <label>Risk level: {riskLevel}</label>
          <Slider
            value={riskLevel}
            onChange={(v) => setRiskLevel(v as number)}
            min={1}
            max={10}
            step={1}
            marks={{ 1: '1', 10: '10' }}
            tooltip={false}
          />
        </div>
        <div className={styles.formField}>
          <label>Daily drawdown: {dailyDrawdown}%</label>
          <Slider
            value={dailyDrawdown}
            onChange={(v) => setDailyDrawdown(v as number)}
            min={1}
            max={25}
            step={1}
            marks={{ 1: '1%', 25: '25%' }}
            tooltip={false}
          />
        </div>
        <div className={styles.formField}>
          <label>Sharpe: {sharpe}</label>
          <Slider
            value={sharpe}
            onChange={(v) => setSharpe(v as number)}
            min={0.5}
            max={4}
            step={0.25}
            marks={{ 0.5: '0.5', 4: '4' }}
            tooltip={false}
          />
        </div>
        <Button>Apply</Button>
      </form>
    </div>
  )
}

export default PortfolioConstructor
