import React from 'react'
import styles from '../Portfolio.module.scss'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import classNames from 'classnames'
import { number2currency } from 'utils'

const FundsChart = React.memo(function FundsChart({ data }: { data: { name: string; y: number; color: string }[] }) {
  const options: Highcharts.Options = {
    chart: {
      type: 'pie',
      width: 300,
      height: 300,
      backgroundColor: 'transparent',
    },
    title: {
      text: '<b>Funds</b>',
      align: 'center',
      verticalAlign: 'middle',
    },

    plotOptions: {
      pie: {
        // enableMouseTracking: false,
        // dataLabels: { enabled: false }
        // showInLegend: true,
        allowPointSelect: false,
        cursor: 'pointer',
        size: 200,
        tooltip: {
          pointFormat: '{point.percentage:.0f}%',
        },
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: 'Funds',
        type: 'pie',
        innerSize: '70%',
        // data: [
        //   { name: 'AAPL', y: 4, sliced: true },
        //   { name: 'BABA', y: 3 },
        //   { name: 'NFLX', y: 3 },
        // ],
        data,
      },
    ],
    // yAxis: {
    //   labels: {
    //     style: {
    //       textOverflow: 'none',
    //     },
    //   },
    // },
  }

  return <HighchartsReact highcharts={Highcharts} options={options} constructorType={'chart'} />
})

type PropsType = {
  assets: AssetPortfolio[]
  equity: number
}
const FundsBlock: React.FC<PropsType> = ({ assets, equity }) => {
  const colors = [
    '#2f7ed8',
    '#0d233a',
    '#8bbc21',
    '#910000',
    '#1aadce',
    '#492970',
    '#f28f43',
    '#77a1e5',
    '#c42525',
    '#a6c96a',
  ]

  console.log('assets', assets)

  const getIsPercent = (quantity: number, priceName: string, price: number) => {
    if (priceName === 'Min. Investment') return ` (${Math.round((quantity / equity) * 100)}%)`
    else return ` (${Math.round(((price * quantity) / equity) * 100)}%)`
  }

  return (
    <div className={classNames([styles.block, styles.foundsBlock])}>
      <div style={{ minWidth: 300, display: 'flex', width: 300, alignSelf: 'center' }}>
        <FundsChart
          data={assets.map((a, i) => {
            return { name: a.seller_product.name, y: a.quantity, color: colors[i] }
          })}
        />
      </div>
      <table className={styles.fundsTable}>
        <colgroup>
          <col style={{ width: '10px', paddingRight: '10px' }} />
          <col />
          <col style={{ width: '120px' }} />
        </colgroup>
        <tbody>
          {assets.map((a, i) => (
            <tr key={a.id}>
              <td style={{ background: colors[i] }}></td>
              <td style={{ paddingLeft: 12 }}>{a.seller_product.name}</td>
              <td style={{ textAlign: 'end' }}>
                {number2currency(a.quantity) +
                  getIsPercent(a.quantity, a.seller_product.price_name, a.seller_product.price)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default FundsBlock
