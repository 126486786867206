import React from 'react'
import styles from '../Portfolio.module.scss'
import classNames from 'classnames'
import { number2currency } from 'utils'
import Button from 'components/Button/Button'
import MainChart from './MainChart'
import { useNavigate } from 'react-router-dom'
import InvestingChartContainer from '../Chart/InvestingChartContainer'

type Props = {
  balance: number
  initialAmmount: number
}

const MainBlock = ({ balance, initialAmmount }: Props) => {
  const navigate = useNavigate()
  const dif = balance - initialAmmount

  return (
    <div className={classNames([styles.block, styles.mainChartBlock])}>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <div>
          <p style={{ fontSize: 20 }}>
            Balance: <b>{number2currency(balance)}</b>
          </p>
          <p style={{ fontSize: 14, color: dif < 0 ? 'var(--color-red)' : 'var(--color-green)' }}>
            {(dif < 0 ? '- ' : '+ ') + number2currency(Math.abs(dif))} (
            {Math.abs((dif / initialAmmount) * 100).toFixed(2)}%)
          </p>
        </div>
        <Button style={{ marginLeft: 'auto' }}>Request update</Button>
        <Button style={{ marginLeft: 16 }} onClick={() => navigate('/portfolio_constructor')}>
          Edit
        </Button>
      </div>
      {/* <MainChart /> */}
      <InvestingChartContainer />
    </div>
  )
}

export default MainBlock
