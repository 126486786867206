import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from './Header.module.scss'
import { useDispatch, useSelector } from '../../store/store'
import { profileActions } from '../../store/profileReducer'
import classNames from 'classnames'
import { LOGO } from '../../App'
import { useClickOutside } from 'hooks/useClickOutside'
import translation from 'assets/locales/en/translation.json'
import api from '../../api'
import SVG from '../SVG'
import LazyLoading from '../LazyLoading/LazyLoading'
import defaultAvatar from '../../assets/images/avatar.png'

const ProfileBtn = () => {
  const dispatch = useDispatch()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const user = useSelector((state) => state.profile)
  const ref = useClickOutside(() => setIsMenuOpen(false))

  const onLogout = () => {
    setIsMenuOpen(false)
    dispatch(profileActions.logout())
    dispatch(api.util.resetApiState())
  }

  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
      <Link to={'/subscriptions'} className={styles.shoppingCartBtn}>
        <SVG.Bell style={{ fill: 'var(--color-text)' }} />
      </Link>
      <div ref={ref} className={styles.profileBtnContainer}>
        <button className={styles.profileBtn} onClick={() => setIsMenuOpen((o) => !o)}>
          <LazyLoading className={styles.avatar} src={user?.avatar_url} noDataImg={defaultAvatar} />
        </button>
        {isMenuOpen && (
          <div className={styles.profileBtnMenu}>
            <Link to={'/profile'} onClick={() => setIsMenuOpen(false)}>
              <div className={styles.avatarInMenuContainer}>
                <LazyLoading src={user?.avatar_url} noDataImg={defaultAvatar} />
              </div>
              <div
                style={{
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'right',
                }}>
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.username}</span>
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 14, opacity: 0.8 }}>
                  {user.email}
                </span>
              </div>
            </Link>
            <Link to={'/subscriptions'} className={styles.subscriptionsMenuItem} onClick={() => setIsMenuOpen(false)}>
              <SVG.Bell style={{ fill: 'var(--color-text)' }} />
              {translation.Header.subscriptions}
            </Link>
            <Link to={'/login'} onClick={onLogout} style={{ borderTop: '1px solid var(--color-border)' }}>
              <SVG.Logout style={{ height: '18px', stroke: 'var(--color-text)' }} />
              {translation.Header.logout}
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

const HamburgerMenu = () => {
  const dispatch = useDispatch()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const user = useSelector((state) => state.profile)
  const ref = useClickOutside(() => setIsMenuOpen(false))
  const client_id = useSelector((state) => state.profile.client_id)

  const onLogout = () => {
    setIsMenuOpen(false)
    dispatch(profileActions.logout())
    dispatch(api.util.resetApiState())
  }

  return (
    <div style={{ height: '100%', display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
      <Link to={'/subscriptions'} className={styles.shoppingCartBtn}>
        <SVG.Bell style={{ fill: 'var(--color-text)' }} />
      </Link>
      <div ref={ref} className={styles.profileBtnContainer}>
        <button className={styles.hamburgerLines} onClick={() => setIsMenuOpen((o) => !o)}>
          <span className={classNames(styles.line, styles.line1, isMenuOpen && styles.openLine1)}></span>
          <span className={classNames(styles.line, styles.line2, isMenuOpen && styles.openLine2)}></span>
          <span className={classNames(styles.line, styles.line3, isMenuOpen && styles.openLine3)}></span>
        </button>
        {/* <LazyLoading className={styles.avatar} src={user?.avatar_url} noDataImg={defaultAvatar} /> */}
        {isMenuOpen && (
          <div className={styles.profileBtnMenu}>
            <Link to={'/profile'} onClick={() => setIsMenuOpen(false)}>
              <div className={styles.avatarInMenuContainer}>
                <LazyLoading src={user?.avatar_url} noDataImg={defaultAvatar} />
              </div>
              <div
                style={{
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'right',
                }}>
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.username}</span>
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 14, opacity: 0.8 }}>
                  {user.email}
                </span>
              </div>
            </Link>
            <Link to={'/subscriptions'} className={styles.subscriptionsMenuItem} onClick={() => setIsMenuOpen(false)}>
              <SVG.Bell style={{ fill: 'var(--color-text)' }} />
              {translation.Header.subscriptions}
            </Link>

            <Link to={'/products'} className={styles.subscriptionsMenuItem} onClick={() => setIsMenuOpen(false)}>
              <SVG.Products style={{ fill: 'var(--color-text)' }} />
              {translation.Header.products}
            </Link>
            {__DEV__ && client_id && (
              <Link to={'/investing'} className={styles.subscriptionsMenuItem} onClick={() => setIsMenuOpen(false)}>
                <SVG.Investing style={{ fill: 'var(--color-text)' }} />
                {translation.Header.investing}
              </Link>
            )}
            <Link to={'/login'} onClick={onLogout} style={{ borderTop: '1px solid var(--color-border)' }}>
              <SVG.Logout style={{ height: '18px', stroke: 'var(--color-text)' }} />
              {translation.Header.logout}
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

const Header = () => {
  const dispatch = useDispatch()
  const isAuth = useSelector((state) => state.profile.isAuth)
  const emailConfirmed = useSelector((state) => state.profile.email_confirmed)
  const pathname = useLocation().pathname
  const client_id = useSelector((state) => state.profile.client_id)

  const onLogout = () => {
    dispatch(profileActions.logout())
    dispatch(api.util.resetApiState())
  }

  useEffect(() => undefined, [pathname])

  return (
    <header className={styles.header}>
      <nav className={styles.nav}>
        <Link to={'/'}>
          <img src={LOGO} className={styles.logo} alt={''} />
        </Link>

        {isAuth ? (
          emailConfirmed ? (
            <>
              <div className={styles.links}>
                <Link
                  to={'/products'}
                  className={classNames(styles.link, { [styles.activeLink]: pathname.includes('products') })}>
                  Products
                </Link>
                {__DEV__ && client_id && (
                  <Link
                    to={'/investing'}
                    className={classNames(styles.link, { [styles.activeLink]: pathname.includes('investing') })}>
                    Investing
                  </Link>
                )}
              </div>

              <div className={styles.profileBtnBox}>
                <ProfileBtn />
              </div>
              <div className={styles.hamburgerMenuBox}>
                <HamburgerMenu />
              </div>
            </>
          ) : (
            <div className={styles.links}>
              <Link to={'/login'} onClick={onLogout} className={styles.link} style={{ marginLeft: 'auto' }}>
                {translation.Header.logout}
              </Link>
            </div>
          )
        ) : (
          <div className={styles.links}>
            <Link to={'/login'} className={styles.link} style={{ marginLeft: 'auto' }}>
              {translation.Header.login}
            </Link>
          </div>
        )}
      </nav>
    </header>
  )
}

export default Header
