export const IS_TOUCH =
  typeof window !== 'undefined' &&
  // @ts-ignore
  ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)

export const number2currency = (number: number, fractionDigits = 0) => {
  if (number === undefined) return ''
  return new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    //@ts-ignore
    useGrouping: 'min2',
  }).format(number)
}

export const number2Abbr = (number: number) => {
  return new Intl.NumberFormat('en-US', { style: 'decimal', notation: 'compact', compactDisplay: 'short' }).format(
    number,
  )
}

export const roundedIntermediateValues = (min: number, max: number, maxCount = 10): number[] => {
  const getValues = (min: number, max: number, step: number) => {
    const res: number[] = []
    for (let i = min + step; i < max; i += step) res.push(i)
    return res
  }
  const dif = max - min
  if (dif < 100) return []
  const r = ('' + dif).length
  let step = Math.pow(10, r - 2)
  let res = getValues(min, max, step)
  while (res.length > maxCount) {
    step = step * 10
    res = getValues(min, max, step)
  }
  return res
}

export const thumb = (url?: string | null, width = 500) => {
  if (url === null) return undefined
  if (url === undefined) return undefined
  if (!url) return ''
  return (
    `https://wealthback.cecure.io/thumb/unsafe/${width}x0/` +
    encodeURIComponent(url.replace('https://', '').replace('http://', ''))
  )
}

export const isIOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export const isValidEmail = (email: string) => {
  return /.+@.+\..+/.test(email)
}

export const searchParams = (mockUrl: string) => {
  let page
  let userID
  const params = Object.fromEntries(
    mockUrl
      .split('?')
      .slice(1)
      .join('')
      .split('&')
      .map((e) => {
        const [key, value] = e.split('=')
        if (key === 'page') {
          page = value
          return []
        }
        if (key === 'userID') {
          userID = value
          return []
        }
        return [key, value]
      })
      .filter((f) => f.length > 0),
  )

  return { page, userID, params }

  return params
}

export const getHref = (params: any) => {
  let href = window.location.pathname.replace('/from_email', '')

  switch (params.page) {
    case 'Campaign':
      href += '/' + 'campaign_product' + '/' + params.params.campaign
      break
    case 'Product':
      href += '/' + 'product' + '/' + params.params.id
      break

    default:
      href += '/' + 'products'
      break
  }

  return href
}
